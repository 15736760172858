import React from 'react';
import { countryCodeFlags, documentTypes } from 'src/constants';
import { documentStatuses } from 'src/constants/documentStatuses';
import { participantNames } from 'src/constants';
import Goods from './Goods/Goods';
import UzProductList from './ProductList/UzProductList';
import ProductList from './ProductList';
import { DeclarationTable } from './Declaration/Table';
import { tabs, esfTabs } from './DocumentDetails.constants';
import InOutputGoods from './Goods/InOutputGoods';
import TotalThirdCountry from './Total/TotalThirdCountry';
import i18next from 'i18next';
import ActGoodsTable from './Act/ActTable';
import TotalAct from './Total/TotalAct';
import { get, flatten } from 'lodash';
import {
  reasonCancellation,
  reasonEntry,
} from 'src/motp_components/_Requests/RequestList/NotificationOfEntry/NotificationOfEntry.constants';
import moment from 'moment';
import { solveCodeValues } from 'src/motp_components/_Requests/RequestList/ImportThirdCountry/ImportThirdCountry.constants';
import { countryCodes } from 'src/constants';
import EsfGoodsTable from './BasicInfoFabric/EsfBasicInfo/EsfGoodsTable';
import ImportCodes from './Codes/ImportCodes';
import Codes from './Codes/Codes';
import { EsfCodes } from './BasicInfoFabric/EsfBasicInfo/EsfCodes';
import { CrossborderGoods } from './EasCrossborder/CrossborderGoods';
import { CrossborderCises } from './EasCrossborder/CrossborderCises';
import { AcceptGoodsCises } from './AcceptGoods/AcceptGoodsCises';
import { importStatuses } from '../../constants/documentTypes';
import { CisRegistrationGoods } from './CisRegistration/CisRegistrationGoods';
import AicGoods from './AicGoods/AicGoods';
import { CustomsDeclarationCodes } from './CustomsDeclarationCodes/CustomsDeclarationCodes';
import { CrossborderErrors } from './EasCrossborder/CrossborderErrors';
import ProcessingErrors from './ProcessingErrors/ProcessingErrors';
import {InventoryCodes} from "./Codes/InventoryCodes";
import { ValidationCodes } from './Codes/ValidationCodes';

export const checkNeedTabs = (selectedDocument) => {
  const { type, docErrors } = selectedDocument;
  switch (type) {
    case documentTypes.AIC_DISAGGREGATION:
      return !!docErrors;
    default:
      return true;
  }
};

export const prepareTabs = (selectedDocument, hasErrorTab) => {
  const { type, docErrors } = selectedDocument;
  switch (type) {
    case documentTypes.LP_FTS_INTRODUCE_RESPONSE:
    case documentTypes.MTTPS_90:
      if (countryCodeFlags.isUZ) {
        return tabs.filter(tab => !['total', 'goods'].includes(tab.to) && (docErrors ? true : tab.to !== "errors" ));
      }
      return tabs.filter(tab => !['codes', 'total', 'errors'].includes(tab.to));
    case documentTypes.KZ_INTRODUCE_GOODS:
    case documentTypes.UZ_ADMIT_APPLICATION:
      return tabs.filter(tab => !['codes', 'total', 'errors'].includes(tab.to));
    case documentTypes.AIC_AGGREGATION:
      return tabs.filter(tab => !['codes', 'total'].includes(tab.to) && (docErrors ? true : tab.to !== "errors" ));
    case documentTypes.AIC_DISAGGREGATION:
      return tabs.filter(tab => !['codes', 'total', 'goods'].includes(tab.to) && (docErrors ? true : tab.to !== "errors" ));
    case documentTypes.CUSTOMS_DECLARATION:
      return tabs.filter(tab => !['goods', 'total'].includes(tab.to) && (docErrors ? true : tab.to !== "errors" ));
    case documentTypes.TRADE_INVENTORY:
    case documentTypes.INVENTORY:
    case documentTypes.COR_INFO_CM:
      return tabs.filter(tab => !['goods', 'total', 'errors'].includes(tab.to));
    case documentTypes.IMPORT_TRANSIT:
    case documentTypes.KZ_UPD:
      return tabs.filter(tab => !['codes', 'errors'].includes(tab.to));
    case documentTypes.UZ_ESF:
    case documentTypes.UZ_ESF_ADD:
    case documentTypes.UZ_ESF_COR:
      return esfTabs.filter(tab => tab.to !== 'total' && (docErrors ? true : tab.to !== "errors" ));
    case documentTypes.IMPORT:
      if (countryCodeFlags.isUZ) {
        return tabs.filter(tab => !['total'].includes(tab.to) && (docErrors ? true : tab.to !== "errors" ));
      }
    // eslint-disable-next-line no-fallthrough
    case documentTypes.WRITE_OFF:
    case documentTypes.AGGREGATION_DOCUMENT:
    case documentTypes.VALIDATION:
      if (countryCodeFlags.isUZ) {
        return tabs.filter(tab => !['goods','total', 'errors'].includes(tab.to));
      }
      return tabs.filter(tab => !['total', 'errors'].includes(tab.to));
    case documentTypes.EAS_CROSSBORDER: {
      const excludedTabs = hasErrorTab ? ['total'] : ['total', 'errors'];
      return tabs.filter(tab => !excludedTabs.includes(tab.to));
    }
    case documentTypes.LP_ACCEPT_GOODS: {
      const excludedTabs = hasErrorTab ? ['total', 'goods'] : ['total', 'goods', 'errors'];
      return tabs.filter(tab => !excludedTabs.includes(tab.to));
    }
    default:
      return tabs.filter(tab => !['total', 'errors'].includes(tab.to));
  }
};

export const isDownloadButtonHided = (documentType, countryCode) => {
  const resolvedValues = [documentTypes.IMPORT, documentTypes.MTTPS_90];
  return !(countryCode === 'UZ' && resolvedValues.includes(documentType));
};

export const getGoodsRoute = (selectedDocument, props) => {
  const { type } = selectedDocument;
  switch (type) {
    case documentTypes.LP_FTS_INTRODUCE_RESPONSE:
      return (
        <React.Fragment>
          <DeclarationTable declarationDocument={selectedDocument}/>
        </React.Fragment>
      );
    case documentTypes.IMPORT_TRANSIT:
      return (
        <React.Fragment>
          <Goods selectedDocument={selectedDocument}/>
        </React.Fragment>
      );
    case documentTypes.KZ_UPD:
      return (
        <React.Fragment>
          <ActGoodsTable selectedDocument={selectedDocument}/>
        </React.Fragment>
      );
    case documentTypes.IMPORT:
      return props.countryCode === 'UZ' ? (
        <UzProductList selectedDocument={selectedDocument}/>
      ) : (
        <React.Fragment>
          <Goods selectedDocument={selectedDocument}/>
        </React.Fragment>
      );
    case documentTypes.KZ_INTRODUCE_GOODS:
    case documentTypes.MTTPS_90:
      return <InOutputGoods selectedDocument={selectedDocument}/>;
    case documentTypes.AGGREGATION_DOCUMENT:
    case documentTypes.WRITE_OFF:
      return (
        <ProductList
          selectedDocument={selectedDocument}
          isAggregationDocument
          {...props}
        />
      );
    case documentTypes.UZ_ESF:
    case documentTypes.UZ_ESF_COR:
    case documentTypes.UZ_ESF_ADD:
      return (
        <EsfGoodsTable
          selectedDocument={selectedDocument}
        />
      );
    case documentTypes.EAS_CROSSBORDER:
      return (
        <CrossborderGoods
          selectedDocument={selectedDocument}
        />
      );
    case documentTypes.UZ_ADMIT_APPLICATION:
      return (
        <CisRegistrationGoods
          selectedDocument={selectedDocument}
        />
      );
    case documentTypes.AIC_AGGREGATION:
      return (
        <AicGoods selectedDocument={selectedDocument}/>
      );
    default:
      return <ProductList selectedDocument={selectedDocument} {...props} />;
  }
};

export function getDocumentIconTypeByDocType(type) {
  switch (type) {
    case documentTypes.WRITE_OFF:
    case documentTypes.MTTPS_10:
    case documentTypes.MTTPS_90:
      return 'pan';
    case documentTypes.RECEIPT:
      return 'check-bso';
    case documentTypes.RECEIPT_RETURN:
      return 'check-bso-return';
    case documentTypes.AGGREGATION_DOCUMENT:
      return 'agregation';
    case documentTypes.UNIVERSAL_CORRECTION_DOCUMENT:
    case documentTypes.UNIVERSAL_CORRECTION_DOCUMENT_UNSIGNED:
      return 'ucd';
    case documentTypes.UNIVERSAL_CORRECTION_DOCUMENT_FIX:
      return 'ucdi';
    case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT_FIX:
      return 'utdi';
    case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT:
      return 'utd';
    case documentTypes.UNIVERSAL_TRANSFER_DOCUMENT_UNSIGNED:
      return 'upd';
    default:
      return 'menu-document-list';
  }
}

export const getDocumentStatusIconByDocStatus = type => {
  switch (type) {
    case documentStatuses.IN_PROGRESS:
    case importStatuses.IN_PROGRESS:
    case importStatuses.WAIT_ACCEPTANCE:
    case 0:
    case 1:
    case 3:
    case 8:
    case 13:
    case 14:
    case 15:
    case 18:
    case 19:
      return 'checking';
    case documentStatuses.CHECKED_OK:
    case importStatuses.ACCEPTED:
    case 2:
    case 4:
    case 11:
    case 12:
      return 'issued';
    case documentStatuses.CHECKED_NOT_OK:
    case documentStatuses.PROCESSING_ERROR:
    case importStatuses.CHECKED_NOT_OK:
    case 5:
    case 10:
      return 'error-status';
    default:
      return '';
  }
};

export const kopecksToRubels = val => {
  return val ? (+('' + val).replace(/,/g, '.') / 100).toFixed(2) : val;
};

export const nano2milli = val => {
  return Number.isInteger(val) && val > 999999999999
    ? Math.trunc(val / 1000)
    : val;
};

export const getParticipantNamesByRoles = roles => {
  const filteredRoles = roles.filter(role => role !== 'TRADE_PARTICIPANT');
  const rolesRus = filteredRoles
    .map(role => participantNames[role])
    .filter(role => role);
  return rolesRus.length > 0 ? rolesRus.join(' / ') : '';
};

export const getDocumentReasonNameByReason = reason => {
  switch (reason) {
    case 'DEFECT':
      return i18next.t('Брак');
    case 'EXPIRY':
      return i18next.t('Истек срок годности');
    case 'QA_SAMPLES':
      return i18next.t('Лабораторные образцы');
    case 'PRODUCT_RECALL':
      return i18next.t('Отзыв с рынка');
    case 'COMPLAINTS':
      return i18next.t('Рекламации');
    case 'PRODUCT_TESTING':
      return i18next.t('Тестирование продукта');
    case 'DEMO_SAMPLES':
      return i18next.t('Демонстрационные образцы');
    case 'OTHER':
      return 'Другие причины';
    default:
      return '-';
  }
};

export const getTotalRoute = selectedDocument => {
  const { type } = selectedDocument;
  switch (type) {
    case documentTypes.IMPORT_TRANSIT:
      return (
        <React.Fragment>
          <TotalThirdCountry selectedDocument={selectedDocument}/>
        </React.Fragment>
      );
    case documentTypes.KZ_UPD:
      return (
        <React.Fragment>
          <TotalAct/>
        </React.Fragment>
      );
    default:
      return <React.Fragment/>;
  }
};

export const getErrorsRoute = selectedDocument => {
  const { type } = selectedDocument;
  const defaultOutputErrors = (
      <React.Fragment>
        <CrossborderErrors selectedDocument={selectedDocument}/>
      </React.Fragment>
  )
  if (countryCodeFlags.isKZ) {
    return defaultOutputErrors;
  }
  switch (type) {
    case documentTypes.UZ_ESF:
    case documentTypes.UZ_ESF_ADD:
    case documentTypes.UZ_ESF_COR:
    case documentTypes.AIC_AGGREGATION:
    case documentTypes.AIC_DISAGGREGATION:
    case documentTypes.CUSTOMS_DECLARATION:
    case documentTypes.IMPORT:
    case documentTypes.WRITE_OFF:
    case documentTypes.AGGREGATION_DOCUMENT:
    case documentTypes.MTTPS_90:
      return (
          <React.Fragment>
            <ProcessingErrors selectedDocument={selectedDocument}/>
          </React.Fragment>
      );
    default:
      return defaultOutputErrors;
  }
};

export const getCodesRoute = (selectedDocument, decodedId) => {
  const { type } = selectedDocument;
  switch (type) {
    case documentTypes.IMPORT:
      return (
        <ImportCodes
          selectedDocument={selectedDocument}
          documentId={decodedId}
        />
      );
    case documentTypes.CUSTOMS_DECLARATION:
      return (
        <CustomsDeclarationCodes
          selectedDocument={selectedDocument}
        />
      );
    case documentTypes.TRADE_INVENTORY:
    case documentTypes.INVENTORY:
    case documentTypes.COR_INFO_CM:
      return (
        <InventoryCodes
          selectedDocument={selectedDocument}
        />
      );
    case documentTypes.UZ_ESF:
    case documentTypes.UZ_ESF_ADD:
    case documentTypes.UZ_ESF_COR:
      return (
        <React.Fragment>
          <EsfCodes selectedDocument={selectedDocument}/>
        </React.Fragment>
      );
    case documentTypes.EAS_CROSSBORDER:
      return (<CrossborderCises selectedDocument={selectedDocument}/>);
    case documentTypes.LP_ACCEPT_GOODS:
      return (<AcceptGoodsCises selectedDocument={selectedDocument}/>);
    case documentTypes.VALIDATION:
      return (<ValidationCodes selectedDocument={selectedDocument}/>);
    default:
      return <Codes type={type} documentId={decodedId}/>;
  }
};

export const buildDocumentForPrint = data => {

  const docType = get(data, 'selectedDocument.type');

  const path =
    docType === documentTypes.IMPORT
      ? 'import'
      : docType === documentTypes.KZ_INTRODUCE_GOODS
      ? 'introduce-goods'
      : 'mttps-90';

  const docTitle =
    docType === documentTypes.IMPORT
      ? 'Уведомление о ввозе товаров (третьи страны).pdf'
      : docType === documentTypes.KZ_INTRODUCE_GOODS
      ? 'Уведомление о вводе в оборот.pdf'
      : 'Уведомление о выводе в оборот.pdf';

  const docData =
    docType === documentTypes.IMPORT
      ? buildImportForPrint(data)
      : buildIntroduceGoodsForPrint(data, docType);

  return {
    path,
    docTitle,
    docData,
  };
};

const buildIntroduceGoodsForPrint = (data, docType) => {
  const { id, selectedDocument } = data;
  const originalDocInfo = get(
    selectedDocument,
    'body.document.mainInfo.originalDocInfo',
    {},
  );
  const reasonValue = get(selectedDocument, 'body.document.mainInfo.reason');

  const list =
    docType === documentTypes.KZ_INTRODUCE_GOODS
      ? reasonEntry
      : reasonCancellation;

  const reason = list.find(item => item.value === reasonValue);
  const codes = get(selectedDocument, 'body.document.productList', []).map(
    item => item.value,
  );

  const result = {
    iin: selectedDocument.senderInn,
    org: selectedDocument.senderName,
    docType: get(originalDocInfo, 'docName', '-'),
    docNum: get(originalDocInfo, 'docNumber', '-'),
    docDate: get(originalDocInfo, 'docDate', '-'),
    reason: get(reason, 'label', '-'),
    orgSign: selectedDocument.senderName,
    signatorySign: selectedDocument.participantFio,
    certSign: selectedDocument.certificateSerialNumber,
    validitySign: selectedDocument.certificateValidTo ? moment(selectedDocument.certificateValidTo).format(
      'DD.MM.YYYY HH:mm:ss',
    ) : '-',
    docId: id,
    regDateTime: moment(selectedDocument.documentDate).format(
      'DD.MM.YYYY HH:mm:ss',
    ),
    codes,
  };

  return docType === documentTypes.MTTPS_90
    ? { kz_mttps90_info: result }
    : { kz_introduce_goods_info: result };
};

const buildImportForPrint = data => {
  const { id, selectedDocument } = data;
  const solveCode = solveCodeValues.find(
    item => item.value === selectedDocument.decisionCode,
  );
  return {
    kz_import_info: {
      iin: selectedDocument.importerCode,
      org: selectedDocument.importerName,
      orgSign: selectedDocument.senderName,
      signatorySign: selectedDocument.participantFio,
      certSign: selectedDocument.certificateSerialNumber,
      validitySign: moment(selectedDocument.certificateValidTo).format(
        'DD.MM.YYYY HH:mm:ss',
      ),
      regNum: id,
      regDate: moment(selectedDocument.documentDate).format('DD.MM.YYYY'),
      declarationNum: selectedDocument.customRegNumber,
      declarationDate: moment(selectedDocument.customRegDate).format(
        'DD.MM.YYYY',
      ),
      acceptDateTime: moment(selectedDocument.decisionDateTime)
        .utc()
        .format('DD.MM.YYYY HH:mm'),
      decisionCode: selectedDocument.decisionCode,
      decisionCodeInfo: get(solveCode, 'label', '-').slice(2),
      conformityConfirmDoc: selectedDocument.certificateDocType,
      conformityConfirmNum: selectedDocument.certificateDocNumber,
      conformityConfirmDate: moment(selectedDocument.certificateDocDate).format(
        'DD.MM.YYYY',
      ),
      primaryNum: selectedDocument.originalDocNumber,
      primaryDate: selectedDocument.originalDocDate
        ? moment(selectedDocument.originalDocDate).format('DD.MM.YYYY')
        : '-',
      products: selectedDocument.disaggregationCodes
        ? getProductsForImportOld(
          selectedDocument.items,
          selectedDocument.disaggregationCodes,
        )
        : getProductsForImportNew(selectedDocument.items),
    },
  };
};

const getProductsForImportNew = items => {
  return items.map(item => {
    const product = item.products[0];
    return {
      code: item.iCodeFromDeclaration,
      tnved: get(product, 'tnved', ''),
      dtNum: get(product, 'itemNumber', ''),
      country: product ? getCountryName(product.originCountry) : '',
    };
  });
};

const getProductsForImportOld = (items, disaggregationCodes) => {
  const products = flatten(items.map(item => item.products));
  const allCodes = flatten(
    Object.keys(disaggregationCodes.gtinGrouped).map(
      gtin => disaggregationCodes.gtinGrouped[gtin],
    ),
  );
  const structure = get(disaggregationCodes, 'structure', {});
  return items.map(item => {
    const codeForSearch = structure[item.iCodeFromDeclaration][0];
    const find = allCodes.find(code => code.cis === codeForSearch);

    const gtin = get(find, 'gtin', '-');
    const product = products.find(pr => pr.gtin === gtin);
    return {
      code: item.iCodeFromDeclaration,
      tnved: get(product, 'tnved', ''),
      dtNum: get(product, 'itemNumber', ''),
      country: product ? getCountryName(product.originCountry) : '',
    };
  });
};

const getProducts = list =>
  list.map(item => {
    const product = item.products[0];
    return {
      code: item.icode,
      tnved: get(product, 'tnved', ''),
      dtNum: get(product, 'itemNumber', ''),
      country: product ? getCountryName(product.originCountry) : '',
    };
  });

const getCountryName = country => {
  const find = countryCodes.find(item => item.type === country);
  return find ? find.title : '-';
};

export const getActLinks = (data, id, direction) => {
  const docType = data.type;
  const links = get(data, 'links', {});
  const keys = Object.keys(links);
  const ids = keys
    .filter(key => key !== id)
    .filter(key => (docType === 1100 ? links[key] !== 1005 : key));
  return ids.map(id => ({
    id,
    type: links[id],
    link: getLinkByDocType(links[id], id, direction),
    label:
      links[id] === 1000
        ? 'Исходный акт'
        : links[id] === 1100
        ? 'Корректировочный акт'
        : 'Уведомление о расхождениях',
  }));
};

const getLinkByDocType = (type, id, direction) => {
  if (direction === 'act-out') {
    return type === 1100 || type === 1000
      ? `/documents/list/act-out/${id}`
      : `/documents/discrepancy_notice/${id}`;
  } else {
    return type === 1100 || type === 1000
      ? `/documents/list/act-in/${id}`
      : `/documents/discrepancy_notice/view/${id}`;
  }
};
